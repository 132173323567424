import matter from 'gray-matter';
import { marked } from 'marked';
import { gfmHeadingId } from 'marked-gfm-heading-id';
import { mangle } from 'marked-mangle';
import katex from 'katex';

// Configure marked with extensions
marked.use(gfmHeadingId());
marked.use(mangle());

// Custom renderer for math
const renderer = {
  code(code, language) {
    if (language === 'math') {
      try {
        return katex.renderToString(code, {
          throwOnError: false,
          displayMode: true
        });
      } catch (err) {
        console.error('KaTeX error:', err);
        return code;
      }
    }
    return false; // Use default renderer for other languages
  }
};

marked.use({ renderer });

export async function getPostBySlug(slug) {
  try {
    const fullPath = `/src/content/blog/${slug}.md`;
    const fileContents = await fetch(fullPath).then(res => res.text());
    
    // Use gray-matter to parse the post metadata section
    const { data, content } = matter(fileContents);
    
    // Use marked to convert markdown into HTML string
    const contentHtml = marked.parse(content);

    return {
      slug,
      contentHtml,
      ...data
    };
  } catch (error) {
    console.error(`Error processing blog post ${slug}:`, error);
    return null;
  }
}

export async function getAllPosts() {
  try {
    const response = await fetch('/api/posts');
    const posts = await response.json();
    
    // Sort posts by date
    return posts.sort((post1, post2) => {
      return new Date(post2.date) - new Date(post1.date);
    });
  } catch (error) {
    console.error('Error fetching posts:', error);
    return [];
  }
}

export function getRelatedPosts(currentPost, allPosts, limit = 3) {
  // Filter out the current post
  const otherPosts = allPosts.filter(post => post.slug !== currentPost.slug);
  
  // Calculate relevance score based on shared tags
  const postsWithScore = otherPosts.map(post => {
    const sharedTags = post.tags.filter(tag => currentPost.tags.includes(tag));
    return {
      ...post,
      relevanceScore: sharedTags.length
    };
  });
  
  // Sort by relevance score and return top N posts
  return postsWithScore
    .sort((a, b) => b.relevanceScore - a.relevanceScore)
    .slice(0, limit);
}

// Helper function to clean HTML and decode entities
function cleanHtmlAndEntities(text) {
  // Remove HTML tags
  let cleaned = text.replace(/<[^>]*>/g, '');
  
  // Decode HTML entities
  const textarea = document.createElement('textarea');
  textarea.innerHTML = cleaned;
  cleaned = textarea.value;
  
  return cleaned;
}

// Footnote utilities
export function extractFootnotes(content) {
  const footnoteRegex = /\[\^(\d+)\]:\s*(.*?)(?=\n\n|\n\[\^|$)/gs;
  const extractedFootnotes = [];
  let match;
  let contentWithoutFootnotes = content;

  while ((match = footnoteRegex.exec(content)) !== null) {
    extractedFootnotes.push({
      number: match[1],
      text: cleanHtmlAndEntities(match[2].trim())
    });
  }

  contentWithoutFootnotes = contentWithoutFootnotes.replace(footnoteRegex, '');
  return { extractedFootnotes, contentWithoutFootnotes };
}

export function computeFootnotePositions(footnoteElements, contentContainer) {
  const containerRect = contentContainer.getBoundingClientRect();
  const positions = {};
  const scrollTop = window.scrollY;

  footnoteElements.forEach(element => {
    const number = element.getAttribute('data-footnote');
    if (!number) return;

    const rect = element.getBoundingClientRect();
    
    // Calculate absolute position relative to the document
    const absoluteTop = rect.top + scrollTop;
    const containerAbsoluteTop = containerRect.top + scrollTop;
    
    // Calculate relative position from container top
    const relativeTop = absoluteTop - containerAbsoluteTop;
    
    positions[number] = {
      top: relativeTop,
      height: rect.height
    };
  });

  return positions;
}

export function splitContentByFootnotes(content) {
  return content.split(/(\[\^(\d+)\])/g);
}

export function findFootnoteByNumber(footnotes, number) {
  return footnotes.find(f => f.number === number);
}

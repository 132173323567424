import React from 'react';
import { Footnote } from '../Footnote';

const MITCCILab = ({ addFootnote, handleFootnoteClick, activeFootnote }) => {
  return (
    <div>
      <h3>MIT Center for Collective Intelligence (CCI Lab) | Research Assistant</h3>
      <p>Jan 2023 - Present | Cambridge, MA</p>
      <ul>
        <li>
          AI tools for empirical improvement of Human-machine teams with swarm multi-agent AI interfaces. "Chain of Agents".
          <Footnote 
            number={8} 
            text="Developing novel approaches to enhance collaboration between humans and AI systems through multi-agent architectures."
            onAdd={addFootnote}
            onClick={handleFootnoteClick}
            active={activeFootnote === 8}
          />
        </li>
      </ul>
    </div>
  );
};

export default MITCCILab;

import React from 'react';
import { Footnote } from '../Footnote';

const SummarySection = ({ addFootnote, handleFootnoteClick, activeFootnote }) => {
  return (
    <section className="about portfolio-section">
      <div style={{ position: 'relative', padding: '0 0 0 0' }}>
        <p>
          I'm a generalist computer scientist, AI researcher, engineer, and philosopher. Currently tinkering with blueprints for AGI. My research paradigm is driven largely by wanting to forge a symbiosis between artificial and organic systems. I have a deep curiosity regarding the nature of complex systems, evolution across different scales and substrates, cognition. I am interested in augmenting collective sense-making. I have experience leading teams, and prefer empirical, data-driven approaches to research and development.
          <Footnote 
            number={1} 
            text="This approach allows for systematic and repeatable processes, increasing reliability and objectivity."
            onAdd={addFootnote}
            onClick={handleFootnoteClick}
            active={activeFootnote === 1}
          />
        </p>
      </div>
    </section>
  );
};

export default SummarySection;

// HomePage.js
import React, { Suspense } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Canvas } from '@react-three/fiber';
import { Link } from 'react-router-dom';

import ThreeDBlogPostTile from './ThreeDBlogPostTile';
import RotatingBrain from './RotatingBrain';
import StillLife from './test-components/StillLife';
import AlifCape from './test-components/AlifCape';
import RotatingCoin from './RotatingCoin';
import CMBCanvas from './CMBRSphere';

import profileImage from './profile-image.jpg';
import Bio from './Bio';
import Contact from './Contact';

import './styling/HomePage.css';
import './styling/Header.css';
import './styling/Contact.css';
import './styling/Bio.css';
import './styling/Footer.css';
import './styling/Coin.css';

function HomePage({ blogPosts = [] }) {
  const username = 'Alif Jakir';

  return (
    <div className="App">
      <header className="header">
        <Container className="main-container">
          <Row>
            <Col md={12} className="profile">
              <div className="model-container">
                <Canvas className="react-canvas" camera={{ position: [25, 6, 40], fov: 40 }}>
                  <RotatingBrain modelPath="/Brain.glb" />
                </Canvas>

                <Canvas className="react-canvas" camera={{ position: [40, -15, 60], fov: 50 }}>
                  <StillLife modelPath="/robohand.glb" />
                </Canvas>
              </div>

              <Link to="/">
                <h1>{username}</h1>
              </Link>
              <p>Transdisciplinary Philosopher-Scientist & Generalist AI Engineer</p>
              <div className="nav-links">
                <Link to="/portfolio">Portfolio</Link>
                <Link to="/blog">Blog</Link>
                <a href="mailto:alifnull@mit.edu">Contact</a>
              </div>
              <Contact />
              <Link to="/portfolio">
                <Button>View Portfolio</Button>
              </Link>
              <Bio />
            </Col>
          </Row>
        </Container>
      </header>
      
      {blogPosts.length > 0 && (
        <section className="blog-section">
          <Container>
            <h2 className="section-title">Latest Blog Posts</h2>
            <div className="blog-tiles-container">
              <Canvas camera={{ position: [0, 0, 20], fov: 50 }}>
                <ambientLight intensity={0.5} />
                <pointLight position={[10, 10, 10]} />
                <Suspense fallback={null}>
                  {blogPosts.map((post, index) => (
                    <ThreeDBlogPostTile
                      key={index}
                      position={[
                        (index % 3 - 1) * 4, // Spread horizontally
                        -Math.floor(index / 3) * 4, // Stack vertically
                        0
                      ]}
                      title={post.title}
                      description={post.description}
                      link={post.link}
                    />
                  ))}
                </Suspense>
              </Canvas>
            </div>
          </Container>
        </section>
      )}

      <footer className="footer">
        <Container className="main-container">
          <p>&copy; {new Date().getFullYear()} Alif Jakir. All rights reserved.</p>
        </Container>
      </footer>
    </div>
  );
}

export default HomePage;

import { useState, useEffect } from 'react';
import matter from 'gray-matter';
import { parseMarkdown, extractTableOfContents } from '../utils/markdownUtils';

// Utility function to extract footnotes from markdown content
const extractFootnotes = (content) => {
  const footnoteRegex = /\[\^(\d+)\]:\s*(.*?)(?=\n\n|\n\[\^|$)/gs;
  const extractedFootnotes = [];
  let match;
  let contentWithoutFootnotes = content;

  while ((match = footnoteRegex.exec(content)) !== null) {
    extractedFootnotes.push({
      number: match[1],
      text: match[2].trim()
    });
  }

  // Remove footnote definitions from the main content
  contentWithoutFootnotes = contentWithoutFootnotes.replace(footnoteRegex, '');

  return { extractedFootnotes, contentWithoutFootnotes };
};

const useMarkdownPost = (blogPosts, slug) => {
  const [parsedData, setParsedData] = useState({
    post: null,
    content: '',
    frontmatter: {},
    cleanContent: '',
    parsedFootnotes: [],
    tableOfContents: [],
    loading: true,
    error: null
  });

  useEffect(() => {
    const processMarkdown = async () => {
      // Reset state when slug changes
      setParsedData(prev => ({ ...prev, loading: true, error: null }));

      // Wait for blogPosts to be loaded
      if (!blogPosts || blogPosts.length === 0) {
        return;
      }

      const foundPost = blogPosts.find(p => p.slug === slug);
      if (!foundPost) {
        setParsedData(prev => ({
          ...prev,
          loading: false,
          error: `Post not found for slug: ${slug}`
        }));
        return;
      }

      try {
        const parsed = matter(foundPost.content);
        const { extractedFootnotes, contentWithoutFootnotes } = extractFootnotes(parsed.content);
        
        // Extract table of contents before parsing markdown
        const tableOfContents = extractTableOfContents(contentWithoutFootnotes);
        
        // First parse the content without footnotes
        const parsedContent = await parseMarkdown(contentWithoutFootnotes);

        // Store footnotes without parsing them through marked
        const processedFootnotes = extractedFootnotes.map(footnote => ({
          ...footnote,
          text: footnote.text
        }));

        setParsedData({
          post: foundPost,
          content: parsedContent,
          frontmatter: parsed.data,
          cleanContent: contentWithoutFootnotes,
          parsedFootnotes: processedFootnotes,
          tableOfContents,
          loading: false,
          error: null
        });
      } catch (error) {
        console.error('Error processing markdown:', error);
        setParsedData(prev => ({
          ...prev,
          loading: false,
          error: 'Error processing markdown content'
        }));
      }
    };

    processMarkdown();
  }, [blogPosts, slug]);

  return parsedData;
};

export default useMarkdownPost;
import React from 'react';
import { Footnote } from '../Footnote';

const NASARASCAL = ({ addFootnote, handleFootnoteClick, activeFootnote }) => {
  return (
    <div>
      <h3>NASA RASC-AL Competition | Lead</h3>
      <p>Sep 2021 - May 2022 | Potsdam, NY</p>
      <ul>
        <li>
          Led large international collaboration for scalable safe autonomous self-charging nuclear modular storage infrastructure system for Lunar South Pole missions. Adhered to NASA Systems Engineering principles.
          <Footnote 
            number={6} 
            text="The project focused on developing sustainable infrastructure for long-term lunar missions, incorporating advanced nuclear power systems and autonomous operations."
            onAdd={addFootnote}
            onClick={handleFootnoteClick}
            active={activeFootnote === 6}
          />
        </li>
      </ul>
    </div>
  );
};

export default NASARASCAL;

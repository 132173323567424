import { marked } from 'marked';
import katex from 'katex';

// Custom renderer to add IDs to headings and handle math
const renderer = {
  heading(text, level) {
    const id = text
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-|-$/g, '');
    return `<h${level} id="${id}">${text}</h${level}>`;
  },
  code(code, language) {
    if (language === 'math') {
      try {
        return katex.renderToString(code, {
          throwOnError: false,
          displayMode: true
        });
      } catch (err) {
        console.error('KaTeX error:', err);
        return code;
      }
    }
    return false; // Use default renderer for other languages
  }
};

marked.use({ renderer });

// Helper function to decode HTML entities and clean tags
export const cleanAndDecodeText = (text) => {
  // First remove HTML tags
  let cleaned = text.replace(/<[^>]*>/g, '');
  
  // Create a temporary element to decode HTML entities
  const txt = document.createElement('textarea');
  txt.innerHTML = cleaned;
  return txt.value;
};

export const parseMarkdown = async (content) => {
  try {
    return marked.parse(content);
  } catch (error) {
    console.error('Error parsing markdown:', error);
    return content;
  }
};

// Extract headers and create TOC structure
export const extractTableOfContents = (content) => {
  const headingRegex = /^(#{1,6})\s+(.+)$/gm;
  const toc = [];
  let match;

  while ((match = headingRegex.exec(content)) !== null) {
    const level = match[1].length;
    const text = match[2].trim();
    // Create slug for anchor links (similar to what marked-gfm-heading-id does)
    const id = text
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-|-$/g, '');

    toc.push({
      level,
      text,
      id
    });
  }

  return toc;
};

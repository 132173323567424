import React, { useEffect, useRef, useState, useLayoutEffect, createContext, useContext } from 'react';
import { cleanAndDecodeText } from '../utils/markdownUtils';
import './styling/Footnote.css';

// Create and export the context with default values
export const FootnoteContext = createContext({
  registerFootnote: () => {},
  registeredFootnotes: {}
});

// Custom hook for using footnote context
const useFootnoteContext = () => {
  const context = useContext(FootnoteContext);
  if (!context) {
    return { registerFootnote: () => {}, registeredFootnotes: {} };
  }
  return context;
};

export const Footnote = ({ number, text, onAdd, onClick, active }) => {
  const footnoteRef = useRef(null);
  const { registerFootnote } = useFootnoteContext();

  useEffect(() => {
    if (onAdd) {
      onAdd(number, text);
    }
  }, [number, text, onAdd]);

  useEffect(() => {
    if (footnoteRef.current) {
      registerFootnote(number, footnoteRef.current);
    }
  }, [number, registerFootnote]);

  return (
    <a
      href={`#footnote-${number}`}
      ref={footnoteRef}
      className={`footnote ${active ? 'active' : ''}`}
      onClick={(e) => onClick && onClick(number, e)}
      data-footnote={number}
    >
      <sup>[{number}]</sup>
    </a>
  );
};

export const FootnoteSidenote = ({ number, text, position, isActive, onSidenoteClick }) => {
  if (!position) return null;

  // Clean and decode the text
  const cleanText = cleanAndDecodeText(text);

  return (
    <div 
      className={`sidenote ${isActive ? 'active' : ''}`}
      style={{
        marginTop: position.marginTop
      }}
      onClick={() => onSidenoteClick && onSidenoteClick(number)}
      data-footnote={number}
      data-sidenote={number}
    >
      <sup>[{number}]</sup> {cleanText}
    </div>
  );
};

export const FootnotesProvider = ({ children }) => {
  const [registeredFootnotes, setRegisteredFootnotes] = useState({});

  const registerFootnote = React.useCallback((number, element) => {
    setRegisteredFootnotes(prev => ({
      ...prev,
      [number]: element
    }));
  }, []);

  const value = React.useMemo(() => ({
    registerFootnote,
    registeredFootnotes
  }), [registerFootnote, registeredFootnotes]);

  return (
    <FootnoteContext.Provider value={value}>
      {children}
    </FootnoteContext.Provider>
  );
};

export const FootnotesContainer = ({ footnotes, activeFootnote, onSidenoteClick }) => {
  const [positions, setPositions] = useState({});
  const containerRef = useRef(null);
  const { registeredFootnotes } = useFootnoteContext();

  // Calculate all positions in a single pass
  const calculatePositions = React.useCallback(() => {
    if (!registeredFootnotes || Object.keys(registeredFootnotes).length === 0) {
      return;
    }

    const mainContent = document.querySelector('.main-content');
    if (!mainContent || !containerRef.current) return;

    const mainRect = mainContent.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const mainTop = mainRect.top + scrollTop;

    const newPositions = {};
    Object.entries(registeredFootnotes).forEach(([number, element]) => {
      if (element && element.getBoundingClientRect) {
        const rect = element.getBoundingClientRect();
        const absoluteTop = rect.top + scrollTop;
        newPositions[number] = {
          marginTop: absoluteTop - mainTop,
          right: rect.right
        };
      }
    });

    setPositions(newPositions);
  }, [registeredFootnotes]);

  // Calculate positions after layout and when footnotes change
  useLayoutEffect(() => {
    calculatePositions();
  }, [calculatePositions]);

  // Recalculate on resize
  useEffect(() => {
    const handleResize = () => {
      calculatePositions();
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleResize);

    // Initial calculation
    calculatePositions();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleResize);
    };
  }, [calculatePositions]);

  return (
    <div className="footnotes-container" ref={containerRef}>
      {footnotes.map(footnote => (
        <FootnoteSidenote
          key={footnote.number}
          number={footnote.number}
          text={footnote.text}
          position={positions[footnote.number]}
          isActive={activeFootnote === footnote.number}
          onSidenoteClick={onSidenoteClick}
        />
      ))}
    </div>
  );
};

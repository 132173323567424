import React from 'react';

const EducationSection = () => {
  return (
    <section className="education portfolio-section">
      <h2>Education</h2>
      <div>
        <h3>Clarkson University</h3>
        <p>B.S. in Computer Science | B.S. in Business Intelligence & Data Analytics | Minors in Robotics, Math</p>
        <p>May 2023 | Potsdam, NY</p>
      </div>
    </section>
  );
};

export default EducationSection;

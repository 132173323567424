import React from 'react';
import { Footnote } from '../Footnote';

const MITImmersionLab = ({ addFootnote, handleFootnoteClick, activeFootnote }) => {
  return (
    <div>
      <h3>MIT Immersion Lab @ MIT Nano | Research Assistant</h3>
      <p>Sep 2023 - Present | Cambridge, MA</p>
      <ul>
        <li>
          XRAgents architecture framework for interaction with immersive 3D AI characters, applied to virtual training, therapeutics.
          <Footnote 
            number={9} 
            text="Creating next-generation interfaces for human-AI interaction in immersive environments."
            onAdd={addFootnote}
            onClick={handleFootnoteClick}
            active={activeFootnote === 9}
          />
        </li>
      </ul>
    </div>
  );
};

export default MITImmersionLab;

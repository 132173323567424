import React, { useEffect } from 'react';

const PortfolioNavigation = ({ contentRef }) => {
  const sections = [
    { id: 'summary', label: 'Summary' },
    { id: 'timeline', label: 'Timeline' },
    { id: 'experience', label: 'Experience' },
    { id: 'research', label: 'Research' },
    { id: 'education', label: 'Education' },
    { id: 'skills', label: 'Skills' },
    { id: 'projects', label: 'Projects' }
  ];

  useEffect(() => {
    console.log('PortfolioNavigation mounted');
    console.log('ContentRef:', contentRef);
  }, [contentRef]);

  const scrollToSection = (e, sectionId) => {
    console.log('Button clicked!');
    console.log('Event:', e);
    console.log('Section ID:', sectionId);
    
    e.preventDefault();
    e.stopPropagation();
    
    const section = document.getElementById(sectionId);
    console.log('Found section:', section);
    
    if (section) {
      console.log('Section position:', section.getBoundingClientRect());
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      console.log('Calculated scroll position:', y);
      
      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });
      console.log('Scroll executed');
    } else {
      console.log('Section not found!');
    }
  };

  const handleMouseOver = (id) => {
    console.log('Mouse over button:', id);
  };

  return (
    <div className="left-sidebar" onClick={(e) => console.log('Sidebar clicked', e)}>
      <nav className="portfolio-navigation" onClick={(e) => console.log('Nav clicked', e)}>
        <h3>Navigation</h3>
        <ul>
          {sections.map(({ id, label }) => {
            console.log('Rendering button for section:', id);
            return (
              <li key={id}>
                <button 
                  type="button"
                  onClick={(e) => scrollToSection(e, id)}
                  onMouseOver={() => handleMouseOver(id)}
                  className="nav-button"
                  style={{ pointerEvents: 'auto' }}
                >
                  {label}
                </button>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default PortfolioNavigation;

import React from 'react';
import { Footnote } from '../Footnote';

const StealthStartup = ({ addFootnote, handleFootnoteClick, activeFootnote }) => {
  return (
    <div>
      <h3>Stealth Startup | Co-Chief Executive Officer</h3>
      <p>Sept 2023 - Present | Cambridge, MA</p>
      <ul>
        <li>Co-leading team on systems engineering design, multiphysics simulation, metamaterial design, programmable metasurfaces, and novel additive manufacturing techniques for applications in Synthetic Biology.</li>
        <li>
          Skills: Engineering Physics, Materials Science, Modeling and Simulation, Nanotechnology.
          <Footnote 
            number={3} 
            text="These fields combine to create cutting-edge technologies in synthetic biology and materials science."
            onAdd={addFootnote}
            onClick={handleFootnoteClick}
            active={activeFootnote === 3}
          />
        </li>
      </ul>
    </div>
  );
};

export default StealthStartup;

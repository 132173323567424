import React from 'react';
import { Footnote } from '../Footnote';

const ClarksonGDSC = ({ addFootnote, handleFootnoteClick, activeFootnote }) => {
  return (
    <div>
      <h3>Clarkson Google Developer Student Club | Lead</h3>
      <p>Jun 2021 – May 2023 | Potsdam, NY</p>
      <ul>
        <li>
          Coordinated a technical core team, workshops & lectures on emerging tech, brought speakers from Google. AI pair-programming, XR, web & cloud native development. Collaborated with Clarkson Open Source community.
          <Footnote 
            number={5} 
            text="The club focused on enhancing technical skills and fostering a collaborative learning environment."
            onAdd={addFootnote}
            onClick={handleFootnoteClick}
            active={activeFootnote === 5}
          />
        </li>
      </ul>
    </div>
  );
};

export default ClarksonGDSC;

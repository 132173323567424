import React from 'react';
import { Link } from 'react-router-dom';
import { Footnote } from '../Footnote';

const ProjectItem = ({ 
  title, 
  link, 
  description, 
  footnote,
  addFootnote,
  handleFootnoteClick,
  activeFootnote 
}) => {
  return (
    <div>
      <h3>
        {link ? (
          <Link to={link}>{title}</Link>
        ) : (
          title
        )}
      </h3>
      <p>
        {description}
        {footnote && (
          <Footnote 
            number={footnote.number}
            text={footnote.text}
            onAdd={addFootnote}
            onClick={handleFootnoteClick}
            active={activeFootnote === footnote.number}
          />
        )}
      </p>
    </div>
  );
};

export default ProjectItem;

import React from 'react';
import { Footnote } from '../Footnote';

const HarvardResearch = ({ addFootnote, handleFootnoteClick, activeFootnote }) => {
  return (
    <div>
      <h3>Harvard | Precipice Fellow @ Harvard EA | AI Incubator, FabulousFables @ HUMIC.ai</h3>
      <p>Sep 2023 - Present | Cambridge, MA</p>
      <ul>
        <li>
          Analysis of existential risks from technological growth, policy considerations, future forecasting with Precipice Fellows.
          <Footnote 
            number={7} 
            text="Research focuses on understanding and mitigating potential risks from advanced AI systems and emerging technologies."
            onAdd={addFootnote}
            onClick={handleFootnoteClick}
            active={activeFootnote === 7}
          />
        </li>
        <li>Leading team-based development, setup infrastructure on AWS for foundation and generative AI model hosting, training, collaboration on software architecture, UI/UX.</li>
      </ul>
    </div>
  );
};

export default HarvardResearch;

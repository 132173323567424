// Portfolio.js
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import profileImage from './profile-image.jpg';
import Timeline from './Timeline';
import PortfolioNavigation from './portfolio/PortfolioNavigation';
import SummarySection from './portfolio/SummarySection';
import EducationSection from './portfolio/EducationSection';
import SkillsSection from './portfolio/SkillsSection';
import ProjectItem from './portfolio/ProjectItem';
import { Footnote, FootnotesContainer, FootnotesProvider } from './Footnote';

// Portfolio Items
import TerascaleResearch from './portfolio-items/TerascaleResearch';
import GeorgiaStateREU from './portfolio-items/GeorgiaStateREU';
import VizcomAI from './portfolio-items/VizcomAI';
import HarvardResearch from './portfolio-items/HarvardResearch';
import MITCCILab from './portfolio-items/MITCCILab';
import MITImmersionLab from './portfolio-items/MITImmersionLab';
import DTUResearch from './portfolio-items/DTUResearch';
import StealthStartup from './portfolio-items/StealthStartup';
import Cybercastellum from './portfolio-items/Cybercastellum';
import ClarksonGDSC from './portfolio-items/ClarksonGDSC';
import NASARASCAL from './portfolio-items/NASARASCAL';
import NYStateGov from './portfolio-items/NYStateGov';

import './styling/Portfolio.css';

const Portfolio = () => {
  const [activeFootnote, setActiveFootnote] = useState(null);
  const [processedFootnotes, setProcessedFootnotes] = useState([]);
  const mainContentRef = useRef(null);

  const scrollToElements = (footnoteEl, sidenoteEl) => {
    if (!footnoteEl || !sidenoteEl) return;

    const footnoteRect = footnoteEl.getBoundingClientRect();
    const sidenoteRect = sidenoteEl.getBoundingClientRect();
    
    const middleY = window.scrollY + 
      (Math.min(footnoteRect.top, sidenoteRect.top) + 
       Math.abs(footnoteRect.top - sidenoteRect.top) / 2);
    
    window.scrollTo({
      top: middleY - window.innerHeight / 2,
      behavior: 'smooth'
    });
  };

  const handleFootnoteClick = (number, event) => {
    event.preventDefault();
    
    if (activeFootnote === number) {
      setActiveFootnote(null);
      return;
    }
    
    setActiveFootnote(number);
    
    const footnoteElement = document.querySelector(`[data-footnote="${number}"]`);
    const sidenoteElement = document.querySelector(`[data-sidenote="${number}"]`);
    
    if (footnoteElement && sidenoteElement) {
      scrollToElements(footnoteElement, sidenoteElement);
    }
  };

  const handleSidenoteClick = (number) => {
    const syntheticEvent = { preventDefault: () => {} };
    handleFootnoteClick(number, syntheticEvent);
  };

  const handleAddFootnote = (number, text) => {
    setProcessedFootnotes(prev => {
      if (!prev.find(f => f.number === number)) {
        return [...prev, { number, text }];
      }
      return prev;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isFootnote = event.target.closest('.footnote');
      const isSidenote = event.target.closest('.sidenote');
      
      if (!isFootnote && !isSidenote) {
        setActiveFootnote(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const projectItems = [
    {
      title: 'XRAgents',
      link: 'https://www.xr.augmentationlab.org/xr-agents',
      description: 'In development open source framework for high fidelity 3D Agents in XR worlds.',
      footnote: {
        number: 11,
        text: 'A cutting-edge framework that combines XR technology with AI agents for immersive interactive experiences.'
      }
    },
    {
      title: 'Moodspace',
      link: 'https://devpost.com/software/moodspace',
      description: 'MIT Reality Hack 2022 | Emotionally aware meta-conversations with Hololens and EEG. Real-time biometric data-driven XR.',
      footnote: {
        number: 12,
        text: 'Pioneering project integrating emotional awareness into XR experiences through EEG and biometric data.'
      }
    },
    {
      title: 'LifeInBetweenXR',
      link: 'https://devpost.com/software/lifeinbetweenxr',
      description: 'MIT Reality Hack 2023 (semifinalist) | Interconnecting locals with their environments. Mobile + 3D Building Mesh Maps.',
      footnote: {
        number: 13,
        text: 'Innovative approach to connecting people with their physical environment through advanced 3D mapping and XR.'
      }
    },
    {
      title: 'EmpathyEase',
      link: 'https://devpost.com/software/echo-101',
      description: 'MIT Reality Hack 2024 | AI in mixed reality, mediating conflicts between people, emphasizing nonviolent communication.',
      footnote: {
        number: 14,
        text: 'Using AI and mixed reality to facilitate better human communication and conflict resolution.'
      }
    },
    {
      title: 'AI YouTube Philosophy Channel for Halcyox',
      link: 'https://www.youtube.com/c/ajphilanthropist/videos',
      description: 'Created software to programmatically generate YouTube videos that received in total 6.6m impressions, 895,000 views, 47,500 watch hours, 6,852 subscribers, and over $3,000 in ad revenue. Content explores narratives, philosophy, humor, existentialism, zeitgeist, the future of civilization, and contemporary topics.',
      footnote: {
        number: 15,
        text: 'Innovative use of AI for content creation, exploring deep philosophical and societal topics while achieving significant audience engagement.'
      }
    }
  ];

  return (
    <FootnotesProvider>
      <div className="portfolio-container">
        <div className="portfolio-layout">
          <PortfolioNavigation contentRef={mainContentRef} />
          
          <main className="main-content" ref={mainContentRef}>
            <header className="header">
              <div className="main-container">
                <div className="profile">
                  <img src={profileImage} alt="Alif Jakir" className="profile-image" />
                  <h1>Alif Jakir</h1>
                  <p>Transdisciplinary Philosopher-Scientist & Generalist AI Engineer</p>
                  <div className="contact">
                    <Link to="mailto:alifnull@mit.edu">Send me an email</Link>
                    <Link to="https://github.com/Caerii">Github</Link>
                    <Link to="https://www.linkedin.com/in/alif-jakir">LinkedIn</Link>
                  </div>
                </div>
              </div>
            </header>

            <section id="summary" className="portfolio-section">
              <h2>Summary</h2>
              <SummarySection 
                addFootnote={handleAddFootnote}
                handleFootnoteClick={handleFootnoteClick}
                activeFootnote={activeFootnote}
              />
            </section>

            <section id="timeline" className="portfolio-section">
              <h2>Timeline</h2>
              <Timeline />
            </section>

            <section id="experience" className="portfolio-section">
              <h2>Experience</h2>
              <div className="experience-items">
                <StealthStartup 
                  addFootnote={handleAddFootnote}
                  handleFootnoteClick={handleFootnoteClick}
                  activeFootnote={activeFootnote}
                />
                <Cybercastellum />
                <VizcomAI 
                  addFootnote={handleAddFootnote}
                  handleFootnoteClick={handleFootnoteClick}
                  activeFootnote={activeFootnote}
                />
                <ClarksonGDSC 
                  addFootnote={handleAddFootnote}
                  handleFootnoteClick={handleFootnoteClick}
                  activeFootnote={activeFootnote}
                />
                <NASARASCAL 
                  addFootnote={handleAddFootnote}
                  handleFootnoteClick={handleFootnoteClick}
                  activeFootnote={activeFootnote}
                />
                <NYStateGov />
              </div>
            </section>

            <section id="research" className="portfolio-section">
              <h2>Research</h2>
              <div className="research-items">
                <HarvardResearch 
                  addFootnote={handleAddFootnote}
                  handleFootnoteClick={handleFootnoteClick}
                  activeFootnote={activeFootnote}
                />
                <MITCCILab 
                  addFootnote={handleAddFootnote}
                  handleFootnoteClick={handleFootnoteClick}
                  activeFootnote={activeFootnote}
                />
                <MITImmersionLab 
                  addFootnote={handleAddFootnote}
                  handleFootnoteClick={handleFootnoteClick}
                  activeFootnote={activeFootnote}
                />
                <DTUResearch 
                  addFootnote={handleAddFootnote}
                  handleFootnoteClick={handleFootnoteClick}
                  activeFootnote={activeFootnote}
                />
                <TerascaleResearch 
                  addFootnote={handleAddFootnote}
                  handleFootnoteClick={handleFootnoteClick}
                  activeFootnote={activeFootnote}
                />
                <GeorgiaStateREU 
                  addFootnote={handleAddFootnote}
                  handleFootnoteClick={handleFootnoteClick}
                  activeFootnote={activeFootnote}
                />
              </div>
            </section>

            <section id="education" className="portfolio-section">
              <h2>Education</h2>
              <EducationSection />
            </section>

            <section id="skills" className="portfolio-section">
              <h2>Skills</h2>
              <SkillsSection />
            </section>

            <section id="projects" className="portfolio-section">
              <h2>Projects</h2>
              <div className="project-items">
                {projectItems.map((item, index) => (
                  <ProjectItem
                    key={index}
                    {...item}
                    addFootnote={handleAddFootnote}
                    handleFootnoteClick={handleFootnoteClick}
                    activeFootnote={activeFootnote}
                  />
                ))}
              </div>
            </section>
          </main>

          <FootnotesContainer
            footnotes={processedFootnotes}
            activeFootnote={activeFootnote}
            onSidenoteClick={handleSidenoteClick}
          />
        </div>
      </div>
    </FootnotesProvider>
  );
};

export default Portfolio;

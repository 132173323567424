import React from 'react';

const SkillsSection = () => {
  return (
    <section className="skills portfolio-section">
      <h2>Skills</h2>
      <p>C/C++, Python, PyTorch, GPT-4, WebGL, Java, C#, JavaScript, R, Matlab, SQL, Flask, Maya, Excel, Tableau, Scikit-learn, ML-Agents, Matplotlib, VirtualBox, Blender, Adobe Suite, Git/VCS, ROS Python, Omniverse, Solidworks, Unity3D, GCP, AWS, BCI, VR, Arduino, TCP/IP, Unix, Windows, Mac, NLP, NLTK, OpenCV</p>
    </section>
  );
};

export default SkillsSection;

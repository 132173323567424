import React from 'react';
import { Footnote } from '../Footnote';

const DTUResearch = ({ addFootnote, handleFootnoteClick, activeFootnote }) => {
  return (
    <div>
      <h3>DTU - Technical University of Denmark | Undergraduate Student Researcher</h3>
      <p>Jun 2023 - Jul 2023 | Kongens Lyngby, Denmark</p>
      <ul>
        <li>Completed courses in Computational Hyperspectral Imaging, Computer Vision, and Optical Planar Waveguide Fabrication.</li>
        <li>
          Led a team to create a solution for Computational Low Light Image Enhancement using Generative Adversarial Networks (GANs). Designed optical ring resonators and waveguides in L-edit and conducted simulations in Ansys Lumerical for silicon fabrication processing in cleanroom.
          <Footnote 
            number={10} 
            text="Combined advanced imaging techniques with machine learning to improve low-light image quality while gaining hands-on experience with optical fabrication."
            onAdd={addFootnote}
            onClick={handleFootnoteClick}
            active={activeFootnote === 10}
          />
        </li>
      </ul>
    </div>
  );
};

export default DTUResearch;

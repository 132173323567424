import React from 'react';

const Cybercastellum = () => {
  return (
    <div>
      <h3>Cybercastellum | Software Engineer</h3>
      <p>Sept 2023 - Dec 2023 | Virtual</p>
      <ul>
        <li>Full stack software engineering, cybersecurity, and data engineering for automation on NY State document backlogs.</li>
      </ul>
    </div>
  );
};

export default Cybercastellum;

import React, { useState, useEffect } from 'react';
import './styling/ThankYouMessage.css';

const ThankYouMessage = () => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      
      // Show message when within 100px of bottom
      setShowMessage(scrollTop + windowHeight >= documentHeight - 100);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check initial position

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!showMessage) return null;

  return (
    <div className="thank-you-message">
      <p>Thanks for reading! 🎉</p>
      <p>Want to discuss this further? Drop me a line at <a href="mailto:blog@alifjakir.com">blog@alifjakir.com</a></p>
    </div>
  );
};

export default ThankYouMessage;

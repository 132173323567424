// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import BlogPost from './components/BlogPost';
import BlogIndex from './components/blog/BlogIndex';
import Portfolio from './components/Portfolio';

// Blog posts metadata
const blogPostsMetadata = [
  {
    title: "How to Create a Virtual Universe",
    description: "Exploring the fundamentals of building a virtual universe, from physics simulations to creating immersive environments.",
    slug: "how-to-create-virtual-universe",
    tags: ["Virtual Reality", "Programming", "Physics"],
    date: "2024-01-15",
    filename: "how-to-create-virtual-universe.md"
  }
  // Add more blog posts here as they are created
];

function App() {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadBlogPosts = async () => {
      try {
        const loadedPosts = await Promise.all(
          blogPostsMetadata.map(async (metadata) => {
            const response = await fetch(`/content/blog/${metadata.filename}`);
            const content = await response.text();
            return {
              ...metadata,
              content,
              link: `/blog/${metadata.slug}` // Ensure link matches slug
            };
          })
        );
        setBlogPosts(loadedPosts);
      } catch (error) {
        console.error('Error loading blog posts:', error);
      } finally {
        setLoading(false);
      }
    };

    loadBlogPosts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage blogPosts={blogPosts} />} />
          <Route path="/blog" element={<BlogIndex blogPosts={blogPosts} />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route 
            path="/blog/:slug" 
            element={<BlogPost blogPosts={blogPosts} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import './styling/TableOfContents.css';

const TableOfContents = ({ toc }) => {
  const [activeId, setActiveId] = useState('');
  const [readingProgress, setReadingProgress] = useState(0);

  useEffect(() => {
    let headingsPositions = [];
    let ticking = false;

    const getHeadingPositions = () => {
      const headings = Array.from(document.querySelectorAll('h1[id], h2[id], h3[id], h4[id], h5[id], h6[id]'));
      headingsPositions = headings.map(heading => ({
        id: heading.id,
        top: heading.getBoundingClientRect().top + window.scrollY,
        bottom: heading.getBoundingClientRect().bottom + window.scrollY
      }));
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const windowHeight = window.innerHeight;
          const windowTop = window.scrollY;
          const windowBottom = windowTop + windowHeight;
          const documentHeight = document.documentElement.scrollHeight;

          // Update reading progress
          const totalHeight = documentHeight - windowHeight;
          const progress = (windowTop / totalHeight) * 100;
          setReadingProgress(Math.min(100, Math.max(0, progress)));

          // Find the active heading
          let currentHeading = null;
          const scrollMid = windowTop + (windowHeight * 0.4);

          // Special case for bottom of page
          if (windowBottom >= documentHeight - 50) {
            // Get the last visible heading
            for (let i = headingsPositions.length - 1; i >= 0; i--) {
              const heading = headingsPositions[i];
              if (heading.bottom <= windowBottom) {
                currentHeading = heading;
                break;
              }
            }
          } else {
            // Normal scrolling behavior
            for (let i = 0; i < headingsPositions.length; i++) {
              const heading = headingsPositions[i];
              const nextHeading = headingsPositions[i + 1];

              if (
                heading.top <= scrollMid &&
                (!nextHeading || nextHeading.top > scrollMid)
              ) {
                currentHeading = heading;
                break;
              }
            }

            if (!currentHeading && windowBottom > documentHeight - windowHeight) {
              for (let i = headingsPositions.length - 1; i >= 0; i--) {
                const heading = headingsPositions[i];
                if (heading.bottom <= windowBottom) {
                  currentHeading = heading;
                  break;
                }
              }
            }
          }

          if (currentHeading) {
            setActiveId(currentHeading.id);
          }
          ticking = false;
        });
        ticking = true;
      }
    };

    // Initial setup
    getHeadingPositions();
    onScroll();

    // Add event listeners
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', getHeadingPositions);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', getHeadingPositions);
    };
  }, []);

  const scrollToHeading = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -100;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <nav className="table-of-contents" aria-label="Table of contents">
      <div className="reading-progress-container">
        <div 
          className="reading-progress-bar"
          style={{ height: `${readingProgress}%` }}
          role="progressbar"
          aria-valuenow={readingProgress}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
      <div className="toc-content">
        <h2>Contents</h2>
        <ul>
          {toc.map((item, index) => (
            <li key={index} className={`heading-${item.level}`}>
              <a
                href={`#${item.id}`}
                onClick={(e) => scrollToHeading(e, item.id)}
                className={activeId === item.id ? 'active' : ''}
                aria-current={activeId === item.id ? 'location' : undefined}
              >
                {item.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default TableOfContents;

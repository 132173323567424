import React, { useState, useEffect, Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { motion } from 'framer-motion';
import ThreeDBlogPostTile from '../ThreeDBlogPostTile';
import './BlogIndex.css';

const BlogIndex = ({ blogPosts }) => {
  const [selectedTag, setSelectedTag] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [allTags, setAllTags] = useState([]);

  useEffect(() => {
    // Extract unique tags from blog posts
    const tags = new Set();
    blogPosts.forEach(post => {
      if (post.tags) {
        post.tags.forEach(tag => tags.add(tag));
      }
    });
    setAllTags(Array.from(tags));
  }, [blogPosts]);

  // Filter posts based on search query and selected tag
  const filteredPosts = blogPosts.filter(post => {
    const matchesSearch = searchQuery === '' || 
      post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (post.description && post.description.toLowerCase().includes(searchQuery.toLowerCase()));
      
    const matchesTag = selectedTag === null || (post.tags && post.tags.includes(selectedTag));
    
    return matchesSearch && matchesTag;
  });

  return (
    <motion.div 
      className="blog-index"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <header className="blog-index-header">
        <h1>Blog</h1>
        <p>Thoughts on AI, Philosophy, and Technology</p>
      </header>

      <div className="blog-filters">
        <input
          type="text"
          placeholder="Search posts..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
        <div className="tag-filters">
          <button
            className={`tag-button ${selectedTag === null ? 'active' : ''}`}
            onClick={() => setSelectedTag(null)}
          >
            All
          </button>
          {allTags.map(tag => (
            <button
              key={tag}
              className={`tag-button ${selectedTag === tag ? 'active' : ''}`}
              onClick={() => setSelectedTag(tag)}
            >
              {tag}
            </button>
          ))}
        </div>
      </div>

      <div className="blog-posts-container" style={{ height: '600px' }}>
        <Canvas camera={{ position: [0, 0, 20], fov: 50 }}>
          <ambientLight intensity={0.5} />
          <pointLight position={[10, 10, 10]} />
          <Suspense fallback={null}>
            {filteredPosts.map((post, index) => {
              const row = Math.floor(index / 3);
              const col = index % 3;
              return (
                <ThreeDBlogPostTile
                  key={post.slug}
                  position={[
                    (col - 1) * 4, // Spread horizontally
                    -(row * 4),    // Stack vertically
                    0
                  ]}
                  title={post.title}
                  description={post.description}
                  link={`/blog/${post.slug}`}
                />
              );
            })}
          </Suspense>
        </Canvas>
      </div>
    </motion.div>
  );
};

export default BlogIndex;

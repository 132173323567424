import React from 'react';

const NYStateGov = () => {
  return (
    <div>
      <h3>New York State Government | Political Intern</h3>
      <p>Aug 2018 - Nov 2018 | Long Island, NY</p>
      <ul>
        <li>Spoke with voters in the field and researched policy to execute an action plan, contributing to the election victory and flipping the New York State Senate legislature.</li>
      </ul>
    </div>
  );
};

export default NYStateGov;

// BlogPost.js
import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { marked } from 'marked';
import { gfmHeadingId } from 'marked-gfm-heading-id';
import { mangle } from 'marked-mangle';
import Prism from 'prismjs';

// Import Prism core styles
import 'prismjs/themes/prism.css';

// Import language support
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-tsx';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-scss';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-glsl';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-rust';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-shell-session';
import 'prismjs/components/prism-docker';
import 'prismjs/components/prism-git';
import 'prismjs/components/prism-graphql';
import 'prismjs/components/prism-regex';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-swift';
import 'prismjs/components/prism-kotlin';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-markup-templating';  // Required for PHP
import 'prismjs/components/prism-php';  // Must come after markup-templating
import 'prismjs/components/prism-r';

// Import plugins
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/show-language/prism-show-language';
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard';

import { Footnote, FootnoteSidenote, FootnotesProvider } from './Footnote';
import TableOfContents from './TableOfContents';
import useMarkdownPost from '../hooks/useMarkdownPost';
import * as blogUtils from '../utils/blogUtils';
import { cleanAndDecodeText } from '../utils/markdownUtils';
import ThankYouMessage from './ThankYouMessage';
import './styling/BlogPost.css';
import './styling/blog/code.css';

// Configure marked with syntax highlighting
marked.setOptions({
  highlight: function(code, lang) {
    if (Prism.languages[lang]) {
      try {
        return Prism.highlight(code, Prism.languages[lang], lang);
      } catch (e) {
        console.warn(`Error highlighting ${lang} code:`, e);
        return code;
      }
    }
    return code;
  }
});

marked.use(gfmHeadingId());
marked.use(mangle());
marked.use({
  breaks: true,
  gfm: true,
  headerIds: true,
  renderer: {
    heading(text, level) {
      const escapedText = text.toLowerCase().replace(/[^\w]+/g, '-');
      return `
        <h${level} id="${escapedText}" class="heading-${level}">
          ${text}
        </h${level}>
      `;
    },
    code(code, infostring, escaped) {
      const lang = (infostring || '').match(/\S*/)[0];
      const title = lang ? `<div class="code-title">${lang}</div>` : '';
      return `
        <div class="code-block-wrapper">
          ${title}
          <div class="code-block">
            <button class="copy-button" aria-label="Copy code">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
              </svg>
            </button>
            <pre class="language-${lang}"><code class="language-${lang}">${code}</code></pre>
          </div>
        </div>
      `;
    }
  }
});

function BlogPost({ blogPosts }) {
  const { slug } = useParams();
  const [footnotes, setFootnotes] = useState([]);
  const [activeFootnote, setActiveFootnote] = useState(null);
  const [footnotePositions, setFootnotePositions] = useState({});
  const [isPersistentSelection, setIsPersistentSelection] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const mainContentRef = useRef(null);

  // Use the custom hook for markdown parsing
  const { 
    post, 
    content, 
    frontmatter, 
    cleanContent, 
    parsedFootnotes,
    tableOfContents, 
    loading, 
    error 
  } = useMarkdownPost(blogPosts, slug);

  // Initialize Prism highlighting and copy buttons
  useEffect(() => {
    if (content) {
      setTimeout(() => {
        // Initialize syntax highlighting
        const codeBlocks = document.querySelectorAll('pre code');
        codeBlocks.forEach((block) => {
          const lang = block.className.match(/language-(\w+)/)?.[1] || 'plaintext';
          block.className = `language-${lang}`;
          Prism.highlightElement(block);
        });

        // Initialize copy buttons
        const copyButtons = document.querySelectorAll('.copy-button');
        copyButtons.forEach(button => {
          button.addEventListener('click', () => {
            const codeBlock = button.nextElementSibling.querySelector('code');
            const code = codeBlock.textContent;
            
            navigator.clipboard.writeText(code).then(() => {
              // Change button appearance temporarily
              const svg = button.querySelector('svg');
              const originalHTML = svg.innerHTML;
              
              // Show checkmark
              svg.innerHTML = `
                <path d="M20 6L9 17l-5-5"></path>
              `;
              button.classList.add('copied');
              
              // Revert back after 2 seconds
              setTimeout(() => {
                svg.innerHTML = originalHTML;
                button.classList.remove('copied');
              }, 2000);
            });
          });
        });
      }, 100);
    }
  }, [content]); // Re-run when content changes

  // Function declarations
  const addFootnote = useCallback((number, text) => {
    setFootnotes(prev => {
      const exists = prev.find(f => f.number === number);
      if (!exists) {
        return [...prev, { number, text }];
      }
      return prev;
    });
  }, []);

  const handleFootnoteClick = useCallback((number) => {
    if (window.innerWidth <= 1024) {
      // On mobile, toggle the footnote content
      setActiveFootnote(prev => prev === number ? null : number);
      setIsPersistentSelection(false);
    } else {
      // On desktop, keep the original sidenote behavior
      if (isPersistentSelection && activeFootnote === number) {
        setActiveFootnote(null);
      } else {
        setActiveFootnote(number);
        setIsPersistentSelection(true);
      }
    }

    const element = document.querySelector(`[data-footnote="${number}"]`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isPersistentSelection, activeFootnote]);

  const handleSidenoteClick = useCallback((number) => {
    handleFootnoteClick(number);
  }, [handleFootnoteClick]);

  const handleClickOutside = useCallback((event) => {
    if (!isPersistentSelection) return;

    const isFootnote = event.target.closest('.footnote');
    const isSidenote = event.target.closest('.sidenote');
    
    if (!isFootnote && !isSidenote) {
      setActiveFootnote(null);
      setIsPersistentSelection(false);
    }
  }, [isPersistentSelection]);

  const updateFootnotePositions = useCallback(() => {
    const mainContent = mainContentRef.current;
    if (!mainContent) return;

    const footnoteElements = mainContent.querySelectorAll('[data-footnote]');
    const contentContainer = mainContent.querySelector('.content');
    if (!contentContainer || !footnoteElements.length) return;

    const containerRect = contentContainer.getBoundingClientRect();
    const positions = {};

    footnoteElements.forEach((element) => {
      const number = element.getAttribute('data-footnote');
      if (!number) return;

      const rect = element.getBoundingClientRect();
      // Get the sup element containing the footnote number
      const supElement = element.querySelector('sup');
      const supRect = supElement ? supElement.getBoundingClientRect() : rect;
      
      // Calculate position relative to the container, using the sup element's position
      const relativeTop = supRect.top - containerRect.top;
      
      positions[number] = {
        marginTop: `${relativeTop}px`
      };
    });

    setFootnotePositions(positions);
  }, []);

  const throttledUpdate = useCallback(() => {
    if (!mainContentRef.current) return;

    requestAnimationFrame(() => {
      updateFootnotePositions();
    });
  }, [updateFootnotePositions]);

  const renderContent = useCallback(() => {
    if (!content) return '';

    const segments = content.split(/(\[\^(\d+)\])/g);
    
    return segments.map((segment, index) => {
      if (index % 3 === 1) {
        const footnoteNumber = segments[index + 1];
        const footnote = parsedFootnotes.find(f => f.number === footnoteNumber);
        if (footnote) {
          return (
            <React.Fragment key={index}>
              <Footnote
                number={footnote.number}
                text={footnote.text}
                onAdd={addFootnote}
                onClick={handleFootnoteClick}
                active={activeFootnote === footnote.number}
              />
              {/* Mobile footnote content */}
              <div 
                className={`mobile-footnote-content ${activeFootnote === footnote.number ? 'active' : ''}`}
                onClick={(e) => e.stopPropagation()}
              >
                {cleanAndDecodeText(footnote.text)}
              </div>
            </React.Fragment>
          );
        }
      } else if (index % 3 === 2) {
        return null;
      }
      // Use the pre-parsed content from marked
      return <div key={index} dangerouslySetInnerHTML={{ __html: segment }} />;
    });
  }, [content, parsedFootnotes, activeFootnote, addFootnote, handleFootnoteClick]);

  useEffect(() => {
    // Highlight all code blocks after content is rendered
    if (mainContentRef.current) {
      const codeBlocks = mainContentRef.current.querySelectorAll('pre code');
      codeBlocks.forEach((block) => {
        Prism.highlightElement(block);
      });
    }
  }, [content]); // Re-run when content changes

  useEffect(() => {
    const resizeObserver = new ResizeObserver(throttledUpdate);
    const contentContainer = mainContentRef.current?.querySelector('.content');
    
    if (contentContainer) {
      resizeObserver.observe(contentContainer);
    }

    // Initial calculation
    const timer = setTimeout(throttledUpdate, 100);

    // Event listeners
    window.addEventListener('scroll', throttledUpdate, { passive: true });
    window.addEventListener('resize', throttledUpdate, { passive: true });
    document.addEventListener('click', handleClickOutside);
    
    // Handle image loads
    const images = mainContentRef.current?.getElementsByTagName('img');
    if (images) {
      Array.from(images).forEach(img => {
        img.addEventListener('load', throttledUpdate);
      });
    }
    
    return () => {
      clearTimeout(timer);
      resizeObserver.disconnect();
      window.removeEventListener('scroll', throttledUpdate);
      window.removeEventListener('resize', throttledUpdate);
      document.removeEventListener('click', handleClickOutside);
      if (images) {
        Array.from(images).forEach(img => {
          img.removeEventListener('load', throttledUpdate);
        });
      }
    };
  }, [throttledUpdate, handleClickOutside]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 1024) {
        const mainContent = mainContentRef.current;
        if (mainContent) {
          const rect = mainContent.getBoundingClientRect();
          if (rect.bottom <= window.innerHeight) {
            setShowThankYouMessage(true);
          } else {
            setShowThankYouMessage(false);
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Debug logs
  console.log('Current slug:', slug);
  console.log('Blog posts:', blogPosts);

  // Find the post directly first
  const directPost = blogPosts?.find(p => p.slug === slug);
  console.log('Direct post found:', directPost);

  // More debug logs
  console.log('Post from hook:', post);
  console.log('Content from hook:', content);
  console.log('Frontmatter:', frontmatter);

  // Wait for blog posts to load
  if (!blogPosts || blogPosts.length === 0) {
    return <div className="loading">Loading blog posts...</div>;
  }

  // Show loading state
  if (loading) {
    return <div className="loading">Loading post...</div>;
  }

  // Handle errors
  if (error) {
    console.error('Error loading post:', error);
    return <Navigate to="/blog" />;
  }

  // If no post is found, redirect
  if (!post) {
    console.log('No post found for slug:', slug);
    return <Navigate to="/blog" />;
  }

  const showTOC = tableOfContents && tableOfContents.length > 0;

  return (
    <FootnotesProvider>
    <div className="blog-post-container">
      <div className="blog-post-layout">
          <aside className="left-sidebar">
            <nav className="table-of-contents-nav">
              <TableOfContents toc={tableOfContents || []} />
            </nav>
        </aside>

        <main className="main-content" ref={mainContentRef}>
            <article className="blog-post">
              <div className="content">
                <h1 className="blog-title">{frontmatter.title}</h1>
                <div className="post-meta">
                  <span className="blog-author">{frontmatter.author}</span>
                  <time className="blog-date">{frontmatter.date}</time>
                </div>
                {frontmatter.tags && (
                  <div className="tag-container">
                    {frontmatter.tags.map((tag, index) => (
                      <span key={index} className="tag">{tag}</span>
                    ))}
                  </div>
                )}
                {renderContent()}
              </div>
              <ThankYouMessage />
            </article>
        </main>

          <aside className="footnotes-container">
            {footnotes.map(footnote => (
              <FootnoteSidenote
                key={footnote.number}
                number={footnote.number}
                text={footnote.text}
                position={footnotePositions[footnote.number]}
                isActive={activeFootnote === footnote.number}
                onSidenoteClick={handleSidenoteClick}
              />
            ))}
        </aside>
      </div>
    </div>
    </FootnotesProvider>
  );
}

export default BlogPost;